var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", {
        attrs: {
          title: "희망하는 금액은 얼마인가요?",
          subtitle: _vm.hasWantPrice
            ? "해당 전문가는 최소 금액 이상만 섭외가 가능해요!"
            : "예산을 알려주세요."
        }
      }),
      _c("p", [_vm._v(_vm._s(_vm.moneyFormat(_vm.price)))]),
      _c("slider", {
        attrs: {
          data: _vm.price,
          min: 0,
          max: _vm.priceLastIndex,
          interval: 1
        },
        on: { cb: _vm.sliderCb }
      }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }