<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`희망하는 금액은 얼마인가요?`"
      :subtitle="hasWantPrice ? '해당 전문가는 최소 금액 이상만 섭외가 가능해요!' : '예산을 알려주세요.'"
    >
    </page-header>
    <p>{{ moneyFormat(price) }}</p>
    <slider
      :data="price"
      :min="0"
      :max="priceLastIndex"
      :interval="1"
      @cb="sliderCb"
    >
    </slider>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import {calcPriceArr, getHasWantPrice} from '@/lib/offerPrice'

import PageHeader from '@/components/common/PageHeader.vue'
import Slider from '@/components/common/Slider.vue'
import FooterBox from '@/components/common/FooterBox.vue'

export default {
  components: {
    PageHeader,
    Slider,
    FooterBox,
  },
  data() {
    const postOffer = this.$store.state.postOffer
    const { _wantPrice } = postOffer
    const hasWantPrice = getHasWantPrice(postOffer)
    const wantPrice = hasWantPrice && _wantPrice || 0
    let {
      priceStart,
      // priceEnd,
    } = postOffer

    const [priceArr, defaultPriceStart, /* defaultPriceEnd */] = calcPriceArr(wantPrice)
    const priceLastIndex = priceArr.length - 1
    priceStart = this.reversePrice(priceStart || defaultPriceStart, priceArr)
    // priceEnd = this.reversePrice(priceEnd || defaultPriceEnd, priceArr)

    return {
      // price: [priceStart, priceEnd],
      price: priceStart,
      priceArr,
      priceLastIndex,
      hasWantPrice,
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return this.price[0] !== null && this.price[1] !== null
      },
    },
  },
  methods: {
    resultPrice(key = 0) {
      return this?.priceArr?.[key] || 0
    },
    reversePrice(price, priceArr = this.priceArr) {
      const key = priceArr.indexOf(parseFloat(price, 10))

      if (key === -1) {
        return 0
      }

      return key
    },
    nextStep() {
      this.postOffer = {
        priceStart: this.resultPrice(this.price),
        priceEnd: this.resultPrice(this.price),
      }
      this.$parent.nextStep()
    },
    sliderCb(val) {
      this.postOffer = {
        priceStart: this.resultPrice(val),
        priceEnd: this.resultPrice(val),
      }
      // this.price[0] = val[0]
      this.price = val
      this.$forceUpdate()
    },
    moneyFormat(priceKey = 0) {
      let postmoney = '원'
      if (priceKey === this.priceLastIndex) {
        postmoney += '+'
      }
      return this.$lib.moneyFormat(this.resultPrice(priceKey)) + postmoney
    }
  },
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 3rem;
  font-size: 2.5rem;
  line-height: 4rem;
  color: #a059b6;
}
</style>
